import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import language files
import en from './utils/locales/en.json';
import es from './utils/locales/es.json';
import fr from './utils/locales/fr.json';

// i18n initialization
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en
    },
    es: {
      translation: es
    },
    fr: {
      translation: fr
    }
  },
  lng: sessionStorage.getItem('language')  , // Default language
  fallbackLng: sessionStorage.getItem('language'),
  interpolation: {
    escapeValue: false // React already safes from XSS
  }
});

export default i18n;