import React, { useState, useEffect, useRef } from "react";
import ApiService from "../../utils/Api";
import io from "socket.io-client";
import { GET_CITIES, GET_TRIPS, GET_USER } from "../../utils/Endpoint";
import { toast } from "react-toastify";
import moment from "moment";
import ReactStars from "react-stars";
import { render } from "react-dom";
import { Router, useNavigate } from "react-router-dom";
import PaymentComponent from "../payment/payment";
import useGMTTime from "../Time/time";
import PaymentCardComponent from "../payWithCard/paymentWithcard";
import { useTimer } from "react-timer-hook";
import DriverIcon from "./DriverIcon";
import { handleRedirectUrl } from "../../utils/Functions";

const SOCKET_SERVER_URL = "https://socket.getservice.ca"; // Your server URL

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    autoStart: true,
    onExpire: () => setExpired(true),
  });
  const [expired, setExpired] = useState(false);

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ fontSize: "25px" }} >
        {!expired ? (
          <div translate="no">
            <span translate="no" >{minutes}</span>:<span translate="no" >{seconds}</span>
          </div>
        ) : (
          <div style={{ fontSize: "15px" }}>
            <span>Timer expired</span>
          </div>
        )}
      </div>
      <p>No cancellation fees will be charged before the timer expires.</p>
    </div>
  );
}
const BidFrom = (props) => {
  const navigate = useNavigate();

  const [connected, setConnected] = useState(false);
  const [loggedUser, setLoggedUser] = useState({});
  const [customOffer, setCustomizeOffer] = useState(false);
  const [offers, setOffers] = useState([]);
  const [offerTrip, setOfferTrip] = useState({});
  const socketRef = useRef(null);
  const [toggle, setToggle] = useState(false);
  const [trip_Id, setTrip_Id] = useState();
  const [driver_Id, setDriver_Id] = useState();
  const [feebackState, setfeebackState] = useState(true);
  const [response, setResponse] = useState([]);
  const [ride, setride] = useState(false);
  const [reason, setReason] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [iosToken, setIosToken] = useState("");
  const [andrToken, setAndrToken] = useState("");
  const [bookingtable, setbookingtable] = useState([]);
  const [socketRunning, setSocketRunning] = useState(false);
  const [thank, setThank] = useState(false);
  const [cardPay, setCardPay] = useState(false);
  const [payAmount, setPayAmount] = useState();
  const [rating, setNewRating] = useState();
  const [previousResponse, setPreviousResponse] = useState([]);

  const [feedback, setFeedback] = useState("");
  const [cancelPopup, setcancelPopup] = useState(false);
  const [driverCancel, setDriverCancel] = useState(true);
  const [tripShow, setTripShow] = useState(true);
  const [tripcancel, settripcancel] = useState(false);

  const tripaccept = sessionStorage.getItem("tripaccept");
  const city_id = sessionStorage.getItem("city_id");
  const userId = sessionStorage?.getItem("userid");
  const api_key = sessionStorage?.getItem("key");
  const userid = sessionStorage?.getItem("userid");

  const storage_trip_id = localStorage?.getItem("trip_id")

  console.log(storage_trip_id,":::::")

  const [cardEndTripPay, setcardEndTripPay] = useState(false);
  const [tripCancelData, setTripCancelData] = useState()

  let notificationId = null;

  function showOfferNotification() {
    // Dismiss any active toast
    if (notificationId) {
      toast.dismiss(notificationId);
    }
  
    // Display a new toast and store its ID
    notificationId = toast.success("You received a new offer!");
  }

  useEffect(() => {
    sessionStorage.setItem("driver_Id", driver_Id);
  }, [driver_Id]);

  const handleCancelClick = (filteredItem) => {
    setTripCancelData(filteredItem)
    setcancelPopup(true);
  };

  const handlePayCardOption = (tripID) => {
    setTrip_Id(tripID);
    if (tripID) {
      sessionStorage.setItem("tripaccept", JSON.stringify(csvData[0]));
    }
    setcardEndTripPay(true);
  };

  const handlePayCardOptionClose = () => {
    setcardEndTripPay(false);
  };

  const [cancelStatus, setCancelStatus] = useState(false);
  const [cancelFee, setcancelFee] = useState(false);

  const handleSubmit = () => {
    setCancelStatus(true);
    setcancelFee(true);
    handleClosepop();
  };

  const handleInputChange = (event) => {
    setFeedback(event.target.value);
  };

  const ratingChanged = (newRating) => {
    setNewRating(newRating);
  };

  const CardPayHandle = () => {
    setCardPay(true);
  };

  const statusValues = {
    request: "Request",
    assigned: "Partner Assigned",
    accept: "Partner Accepted",
    arrive: "Partner Arrived",
    begin: "Begin",
    completed: "Completed",
    cancel: "Cancelled",
    p_cancel_pickup: "Partner Cancel at Pickup Location",
    p_cancel_drop: "Partner Cancel at Drop Location",
    expired: "Expired",
    paid_cancel: "Cancelled",
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getTripHistory();
      handleTripComplete();
    }, 2000); // 20 seconds in milliseconds
    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [userId,api_key]);
  const csvstatus = { Request: "request" };

  const handleButtonClick = () => {
    setShowPopup(!showPopup);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000); // Hide notification after 3 seconds
  };

  const [decline_trip, setdecline_trip] = useState(false);

  const tid = localStorage.getItem("tripid");

  useEffect(() => {
    setTrip_Id(tid);
  }, [tid]);

  const [offerAmt, setOfferAmt] = useState();
  const [otp, setotp] = useState("");
  const timer = sessionStorage.getItem("timer");

  const [expiryTimestamp, setExpiryTimestamp] = useState(null);

  const startTimer = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 60 * timer); // Set the timer for 2 minutes
    setExpiryTimestamp(time);
  };
  const [city_pay_options, setCity_Pay_Options] = useState();

  const uFname = sessionStorage.getItem("fname");
  const uLname = sessionStorage.getItem("lname");
  const tripPayAmountMin = sessionStorage.getItem("tripPayAmountMin");
  const tripPayAmountMax = sessionStorage.getItem("tripPayAmountMax");
  const cityd = localStorage.getItem("cityInfo");
  let cityInfo;



  // useEffect(() => {
  //   setCity_Pay_Options(
  //     cityInfo?.city_pay_options
  //       .split("|")
  //       .map((option, index) => ({
  //         id: index + 1,
  //         name: option.charAt(0).toUpperCase() + option.slice(1) == "Stripe" ? "Card" :  option.charAt(0).toUpperCase() + option.slice(1) , // Capitalize the first letter
  //       }))
  //   );
  // },[cityInfo])

  if (cityd) {
    try {
      cityInfo = JSON.parse(cityd);
   
    } catch (error) {
      console.error("Invalid JSON:", error);
    }
  } else {
    console.error("No city data found");
  }
  var d = new Date();
  var formattedTime = moment.utc(d).format("YYYY-MM-DD HH:mm:ss");
  const trip_pay_mode = sessionStorage.getItem("trip_pay_mode");
  // const { formattedTime } = useGMTTime(cityInfo);

  useEffect(() => {
    setLoggedUser({ userId, uFname, uLname });
  }, [userId]);

  const getTripOffers = async () => {
    if (api_key && storage_trip_id) {
      let params = {
        trip_id: storage_trip_id,
        status: "offer",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/triprequestapi/gettriprequests?api_key=${api_key}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        if (offers?.length < 1 ) {
          // checkAndAddOrRemoveOffer(result.response, "offer")
        }  
        if (result?.response?.length > 0 && !socketRunning ) {
          setPreviousResponse((prevItems) =>
            Array.isArray(prevItems)
              ? [...prevItems, result?.response]
              : [result?.response]
          );

          result?.response?.forEach((item) => {
            // Check if this item is new or has a new modified timestamp compared to the previous response

            checkAndAddOrRemoveOffer(item, item.status);
          });
        } else {
          setOffers([])
        }
      } catch (error) {
        console.error("Error sending notification:", error);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getTripOffers();
    }, 5000); // 20 seconds in milliseconds
    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [userid,api_key]);

  const handleCloseTripDetail = () => {
    sessionStorage.removeItem("ride");
    // localStorage.removeItem("tripid");
    localStorage.removeItem("trip_id");
    settripcancel(false);
    setTripShow(false);
    window.location.reload()
  };

  const getCities = async (trip) => {

    const data = {
      city_id:trip.city_id
    }

    try {
      const response = await ApiService.post(`${GET_CITIES}?api_key=${api_key}`,data);
      if (response.status === 200) {
        console.log(response)

        // setcitydrop(response.data.response.city_pay_options);
        setCity_Pay_Options(
          response?.data?.response[0]?.city_pay_options
            .split("|")
            .map((option, index) => ({
              id: index + 1,
              name: option.charAt(0).toUpperCase() + option.slice(1) == "Stripe" ? "Card" :  option.charAt(0).toUpperCase() + option.slice(1) , // Capitalize the first letter
            }))
        );


      }
    } catch (error) {
      console.log("Error::", error);
    }
    setShowPopup(!showPopup);

  };


  const handlePayClick = (trip) => {
    getCities(trip)
  };
  // console.log("Notification sent:", response);

  useEffect(() => {
    const setupSocket = () => {
      socketRef.current = io(SOCKET_SERVER_URL, {
        autoConnect: false,
        transports: ["polling"],
      });

      socketRef.current.on("connect", () => {
        // console.log("Connected to socket");
        const userJson = {
          user_id: loggedUser.userId,
          name: `${loggedUser.uFname} ${loggedUser.uLname}`,
        };
        socketRef.current.emit("add_user", userJson);
        // console.log("Socket ID:", socketRef.current.id);
        setConnected(true);
      });

      socketRef.current.on("disconnect", () => {
        console.log("Disconnected from socket");
        setConnected(false);
      });

      socketRef.current.on("connect_error", (error) => {
        console.log("Connection error:", error);
      });

      socketRef.current.on("error", (error) => {
        console.log("Error:", error);
      });

      socketRef.current.on("reconnect", () => {
        console.log("Reconnected to socket");
      });

      socketRef.current.on("reconnect_error", (error) => {
        console.log("Reconnect error:", error);
      });

      socketRef.current.on("reconnect_failed", () => {
        console.log("Reconnect failed");
      });

      socketRef.current.on("reconnect_attempt", () => {
        console.log("Reconnect attempt");
      });

      socketRef.current.on("fcm_push_noti", (response) => {
        // console.log("data", response);

        if (response) {
          const tripStatus = response.trip_status;
          if (
            tripStatus === "OFFER" ||
            tripStatus === "REJECT_OFFER" ||
            tripStatus === "offer" ||
            tripStatus === "reject_offer"
          ) {
            setToggle(true);

            const offerData = response.data;
            if (offerData) {
              setSocketRunning(true);
            }
            checkAndAddOrRemoveOffer(offerData, tripStatus);
            if (tripStatus === "OFFER" || tripStatus === "offer") {
              if (!toast.isActive(notificationId)) {
                notificationId = toast.success("You received a new offer!");
              }
                            // showOfferAlert(data.message);
            }
          }
          if (tripStatus === "declined" || tripStatus === "DECLINED") {
            if (!socketRunning) {
              toast.error("Your ride request has been declined by the driver.");
            }
            const offerData = response.data;

            checkAndAddOrRemoveOffer(offerData, tripStatus);

            // setToggle(false);
            // showOfferAlert(data.message);
          }
        }
      });
    };

    if (loggedUser.userId) {
      setupSocket();
      socketRef.current.connect();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.off();
        socketRef.current.disconnect();
      }
    };
  }, [loggedUser, tid]);

  const sendOffer = async (id) => {
    const params = {
      trip_id: trip_Id,
      status: "offer",
      user_offer_amt: offerAmt,
      driver_id: driver_Id,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/triprequestapi/updatetriprequest?api_key=${api_key}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      sendNotification(result);
      toast.success("Driver has received your offer!");
      // console.log("Notification sent:", result);
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const sendNotification = (message) => {
    if (message && message.response) {
      const dt = message.response;
      const objPayload = {
        message: offerTrip.message,
        trip_id: trip_Id,
        trip_status: "offer",
        data: dt,
        to: "driver",
      };

      const jsonObject = {
        driver_id: driver_Id,
        payload: objPayload,

        token: "genericDeviceToken789",
      };

      // Emit notification using WebSocket
      socketRef.current.emit("fcm_push_noti", jsonObject);
    }
  };

  // const checkAndAddOrRemoveOffer = (offerData, status) => {
  //   if (offerData) {
  //     const upperStatus = status.toUpperCase();

  //     if (upperStatus === "OFFER") {
  //       setOffers((prevOffers) => {
  //         const existingOfferIndex = prevOffers?.findIndex(
  //           (offer) => offer.Driver.driver_id === offerData.Driver.driver_id
  //         );

  //         if (existingOfferIndex !== -1) {
  //           const updatedOffers = [...prevOffers];
  //           updatedOffers[existingOfferIndex] = {
  //             ...updatedOffers[existingOfferIndex],
  //             offer_amt: offerData.offer_amt,
  //             user_offer_amt: offerData.user_offer_amt,
  //           };
  //           const existingOffer = prevOffers[existingOfferIndex];

  //           if (
  //             existingOffer.offer_amt !== offerData.offer_amt ||
  //             (existingOffer.user_offer_amt !== offerData.user_offer_amt &&
  //               !socketRunning)
  //           ) {
  //             if (!toast.isActive(notificationId)) {
  //               notificationId = toast.success("You received a new offer!");
  //             }
  //                         }
  //           return updatedOffers;
  //         } else {
  //           return [...prevOffers, offerData];
  //         }
  //       });

  //       setOfferTrip({
  //         message: "You received an offer!",
  //         offeramt: offerData.offer_amt,
  //         useramt: offerData.user_offer_amt,
  //       });
  //     } else if (upperStatus === "REJECT_OFFER" || upperStatus === "DECLINED") {
  //       // toast.error("Your ride request has been declined by the driver.");

  //       setOffers((prevOffers) =>
  //         prevOffers.filter(
  //           (offer) => offer.tripRequestId !== offerData.tripRequestId
  //         )
  //       );

  //       if (upperStatus === "DECLINED") {
  //         setOfferTrip({
  //           message: "Your offer has been declined.",
  //         });
  //       }
  //     }
  //   } else {
  //     // setOffers(response);
  //   }
  // };

  // const tripacceptwithStripe = (amount) => {
  //   setTripShow(true);
  //   setPayAmount(amount);
  //   setCardPay(true);
  //   setTripDone(true);
  //   setSocketRunning(false);
  //   settripcancel(false);
  // };

  const checkAndAddOrRemoveOffer = (offerData, status) => {

    console.log(offerData,status)

    if (offerData) {
      const upperStatus = status.toUpperCase();
  
      if (upperStatus === "OFFER") {
        setOffers((prevOffers) => {
          const existingOfferIndex = prevOffers?.findIndex(
            (offer) => offer.Driver.driver_id === offerData.Driver.driver_id
          );
  
          if (existingOfferIndex !== -1) {
            const updatedOffers = [...prevOffers];
            updatedOffers[existingOfferIndex] = {
              ...updatedOffers[existingOfferIndex],
              offer_amt: offerData.offer_amt,
              user_offer_amt: offerData.user_offer_amt,
            };
            const existingOffer = prevOffers[existingOfferIndex];
          
            if (
              existingOffer.offer_amt !== offerData.offer_amt ||
              (existingOffer.user_offer_amt !== offerData.user_offer_amt && offerData.offer_amt == offerAmt &&
                !socketRunning)
            ) {
              if (!toast.isActive(notificationId)) {
                // notificationId = toast.success("You received a new offer!");
              }
            }
            return updatedOffers;
          } else {
            return [...prevOffers, offerData];
          }
        });
  
        setOfferTrip({
          message: "You received an offer!",
          offeramt: offerData.offer_amt,
          useramt: offerData.user_offer_amt,
        });
      } else if (upperStatus === "REJECT_OFFER" || upperStatus === "DECLINED") {
        setOffers((prevOffers) =>
          prevOffers.filter(
            (offer) => offer.tripRequestId !== offerData.tripRequestId
          )
        );
  
        if (upperStatus === "DECLINED") {
          setOfferTrip({
            message: "Your offer has been declined.",
          });
        }
      }
    } else {
      // Reset the offers array if offerData is null
      setOffers([]);
      setOfferTrip(null); // Optionally clear the offer trip state
    }
  };
  

  const tripSend = async () => {
    const data = {
      trip_id: trip_Id,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/constantapi/paymentsave`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const tripAcceptHandle = async (paymentIntent, offer) => {
    if (!paymentIntent || !offer) return;
  
    // Generate a 4-digit OTP
    const otpval = Array.from({ length: 4 }, () => Math.floor(Math.random() * 10)).join("");
  
    // Find and store driver details
    const driverDetails = offers.find((data) => data.driver_id === offer.driver_id);
    if (driverDetails) {
      sessionStorage.setItem("DriverName", driverDetails.Driver.d_name);
      sessionStorage.setItem("DriverContact", driverDetails.Driver.d_phone);
      sessionStorage.setItem("D_Device", driverDetails.Driver.d_device_type); // Fixed casing
      sessionStorage.setItem("DriverToken", driverDetails.Driver.d_device_token);
    }
    setOffers([]); // Clear offers immediately
  
    // Retrieve tokens directly from sessionStorage
    const dtype = sessionStorage.getItem("D_Device");
    const dtoken = sessionStorage.getItem("DriverToken");
  
    if (dtype === "Android") {
      setAndrToken(dtoken); // Update state
    } else {
      setIosToken(dtoken); // Update state
    }
  
    // Use tokens directly from sessionStorage to avoid delays
    const androidToken = dtype === "Android" ? dtoken : null;
    const iosTokenValue = dtype !== "Android" ? dtoken : null;
  
    if (!androidToken && !iosTokenValue) {
      console.error("No valid tokens available for notification");
      return;
    }
  
    const params2 = {
      trip_id: offer.trip_id,
      trip_status: "accept",
      driver_id: offer.driver_id,
      trip_pay_amount: offer.offer_amt,
      tm_acc: formattedTime,
      otp: otpval,
      payment_intent_id: paymentIntent,
    };
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/tripapi/tripaccept?api_key=${api_key}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(params2),
        }
      );
  
      if (!response.ok) throw new Error("Failed to accept trip");
  
      const data = await response.json();
  
      // Update state and localStorage
      settripcancel(false);
      sessionStorage.setItem("veri-otp", otpval);
      sessionStorage.setItem("ride", true);
      sessionStorage.setItem("tripaccept", JSON.stringify(data.response));
      setDriverCancel(true);
      setOffers([]);
      setride(true);
      setShowPopup(false);
      setTripDone(true);
      startTimer();
      localStorage.removeItem("pm");
  
      // Notify driver
      const notificationParams = {
        trip_id: offer.trip_id,
        trip_status: "accept",
        android: androidToken,
        ios: iosTokenValue,
        to: "driver",
        message: "Your offer has been accepted",
      };
  
      const notificationResponse = await fetch(
        `https://apis.getservice.ca/backend/notification/send?api_key=${api_key}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(notificationParams),
        }
      );
  
      if (!notificationResponse.ok) throw new Error("Failed to send notification");
  
      await notificationResponse.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const tripacceptwithStripe = (offer) => {
    setPayAmount(offer.offer_amt);
  
    fetch(`${process.env.REACT_APP_API_BASE_URL}/constantapi/createpaymentintent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        payment_method: localStorage.getItem("pm"),
        customer: sessionStorage.getItem("stripe_cust_id"),
        amount: offer.offer_amt,
        currency: cityInfo.pg_cur,
        description: "getride booking website",
      }),
    })
      .then((result) => {
        if (!result.ok) throw new Error(`HTTP error! status: ${result.status}`);
        return result.json();
      })
      .then((response) => {
        const paymentIntent = response.response.paymentIntent;
        tripAcceptHandle(paymentIntent, offer); // Pass arguments correctly
        setTripShow(true);
        setSocketRunning(false);
      })
      .catch((error) => {
        console.error("Fetch error: ", error);
      });
  };
  
  
  const tripAccept = async (offer) => {
    setTripShow(true);
    setSocketRunning(false);
  
    setPayAmount(offer.offer_amt);
  
    let range = "0123456789";
    let otpval = "";
    for (let i = 0; i < 4; i++) {
      otpval += range[Math.floor(Math.random() * 10)];
    }
  
    if (!otpval) return;
  
    let driverDetails;
    offers.forEach((data) => {
      if (data.driver_id === offer.driver_id) {
        sessionStorage.setItem("DriverName", data.Driver.d_name);
        sessionStorage.setItem("DriverContact", data.Driver.d_phone);
        sessionStorage.setItem("D_Device", data.Driver.d_device_type);
        sessionStorage.setItem("DriverToken", data.Driver.d_device_token);
        driverDetails = data.Driver;
      }
    });
  
    if (!driverDetails) return;
  
    const dtype = sessionStorage.getItem("D_Device");
    const dtoken = sessionStorage.getItem("DriverToken");
  
    if (dtype === "Android") {
      setAndrToken(dtoken);
    } else {
      setIosToken(dtoken);
    }
  
    // Use a delay to ensure the tokens are updated
    setTimeout(async () => {
      const androidToken = andrToken || dtoken;
      const iosTokenValue = iosToken || dtoken;
  
      if (!iosTokenValue && !androidToken) {
        return;
      }
  
      const params2 = {
        trip_id: offer?.trip_id,
        trip_status: localStorage.getItem("is") === "later" ? "assigned" : "accept",
        driver_id: offer.driver_id,
        trip_pay_amount: offer.offer_amt,
        tm_acc: formattedTime,
        otp: otpval,
      };
  
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/tripapi/${
            localStorage.getItem("is") === "later" ? "tripassigned" : "tripaccept"
          }?api_key=${api_key}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(params2),
          }
        );
  
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
  
        const data = await response.json();
        sessionStorage.setItem("veri-otp", otpval);
        sessionStorage.setItem("ride", true);
        sessionStorage.setItem("tripaccept", JSON.stringify(data.response));
  
         // startTimer();
      
          
            setDriverCancel(true);
            setOffers([]);
            setride(true);
            setShowPopup(false);
            setTripDone(true);
            startTimer();
        const notificationParams = {
          trip_id: offer?.trip_id,
          trip_status: localStorage.getItem("is") !== "later" ? "accept" : "assigned",
          android: androidToken,
          ios: iosTokenValue,
          to: "driver",
          message: "Your offer has been accepted",
        };
  
       const res =  await fetch(
          `https://apis.getservice.ca/backend/notification/send?api_key=${api_key}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(notificationParams),
          }
        );

        if (res) {
          settripcancel(false);
          const is = localStorage?.getItem("is")
          if (is) {
          localStorage.removeItem("is")
          }
        }
      } catch (error) {
        settripcancel(false);
        const is = localStorage?.getItem("is")
        if (is) {
        localStorage.removeItem("is")
        }
        console.error("Error handling trip accept or sending notification:", error);
      }
    }, 50); // Slight delay to ensure tokens are set
  };
  

  // const tripAccept = async (offer) => {
  //   setTripShow(true);
  //   setSocketRunning(false);

  //   setPayAmount(offer.offer_amt
  //     );
  //   var range = "0123456789";
  //   var otpval = "";

  //   for (var i = 0; i < 4; i++) {
  //     otpval += range[Math.floor(Math.random() * 10)];
  //   }

  //   if (!otpval) return;

  //   offers.forEach((data) => {
  //     if (data.driver_id === offer.driver_id) {
  //       sessionStorage.setItem("DriverName", data.Driver.d_name);
  //       sessionStorage.setItem("DriverContact", data.Driver.d_phone);
  //       sessionStorage.setItem("D_Device", data.Driver.d_device_type); // fixed casing
  //       sessionStorage.setItem("DriverToken", data.Driver.d_device_token);
  //     }
  //   });

  //   const dtype = sessionStorage.getItem("D_device");
  //   const dtoken = sessionStorage.getItem("DriverToken");

  //   if (dtype == "Android") {
  //     setAndrToken(dtoken);
  //   } else {
  //     setIosToken(dtoken);
  //   }

  //   if (!iosToken && !andrToken) {
  //     return;
  //   }

  //   console.log(offer?.trip_id,"hjuhikh")

  //   const params2 = {
  //     trip_id: offer?.trip_id,
  //     trip_status: localStorage?.getItem("is") == "later" ? "assigned" : "accept",
  //     driver_id: offer.driver_id,
  //     trip_pay_amount: offer.offer_amt,
  //     tm_acc: formattedTime,
  //     otp: otpval,
  //   };
    

  //    try {
  //     const response = await fetch(
  //   `${process.env.REACT_APP_API_BASE_URL}/tripapi/${localStorage?.getItem("is") == "later" ? 'tripassigned' : 'tripaccept'}?api_key=${api_key}`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(params2),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const data = await response.json();
  //     settripcancel(false);
  //      const is = localStorage?.getItem("is")
  //      if (is) {
  //      localStorage.removeItem("is")
  //      }
  //     sessionStorage.setItem("veri-otp", otpval);
  //     sessionStorage.setItem("ride", true);
  //     sessionStorage.setItem("tripaccept", JSON.stringify(data.response));
  //     setDriverCancel(true);
  //     setOffers([]);
  //     setride(true);
  //     setShowPopup(false);
  //     setTripDone(true);
  //     startTimer();
  //     const params = {
  //       trip_id: offer?.trip_id,
  //       trip_status: localStorage?.getItem("is") !== "later"? "accept":"assigned",
  //       android: andrToken,
  //       ios: iosToken,
  //       to: "driver",
  //       message: "Your offer has been accepted",
  //     };

  //     try {
  //       const response = await fetch(
  //         `https://apis.getservice.ca/backend/notification/send?api_key=${api_key}`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(params),
  //         }
  //       );

  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       const result = await response.json();

  //       // console.log("Notification sent:", result);
  //     } catch (error) {
  //       console.error("Error sending notification:", error);
  //     }

  //     const result = await response.json();

  //     // console.log("Notification sent:", result);
  //   } catch (error) {
  //     console.error("Error sending notification:", error);
  //   }
  // };

  // const rejectNotificationApi = async (offers) => {
  //   const params = {
  //     trip_id: offers?.trip_id ,
  //     status: "decline",
  //     driver_id: offers?.driver_id,
  //     is_user: "1",
  //   };

  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_BASE_URL}/triprequestapi/rejecttriprequest?api_key=${api_key}`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(params),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const result = await response.json();
  //     setdecline_trip(false);
  //     setSocketRunning(false);
  //     setOffers((prevOffers) =>
  //       prevOffers.filter((offer) => offer.Driver.driver_id !== driver_Id)
  //     );

  //     const dt = result.response;
  //     const objPayload = {
  //       message: "Rider has reject your offer.",
  //       trip_id: offers?.trip_id ,
  //       trip_status: "reject",
  //       data: dt,
  //       to: "driver",
  //     };

  //     const jsonObject = {
  //       driver_id: offers?.driver_id,
  //       payload: objPayload,
  //       token: offers?.Driver.d_device_token,
  //     };

  //     // Emit notification using WebSocket
  //     socketRef.current.emit("fcm_push_noti", jsonObject);
  //     // console.log("Notification sent:", result);
  //   } catch (error) {
  //     console.error("Error sending notification:", error);
  //   }
  // };
  const rejectNotificationApi = async (offers) => {
    // Ensure driver ID and offers are defined
    if (!offers?.driver_id || !offers?.trip_id) {
      console.error("Invalid offers object:", offers);
      return;
    }
  
    const params = {
      trip_id: offers.trip_id,
      status: "decline",
      driver_id: offers.driver_id,
      is_user: "1",
    };
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/triprequestapi/rejecttriprequest?api_key=${api_key}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const result = await response.json();
  
      // Safely update states using functional updates
      setdecline_trip(false);
      setSocketRunning(false);
  
      setOffers((prevOffers) =>
        prevOffers.filter((offer) => offer.Driver.driver_id !== offers.driver_id)
      );
  
      const dt = result.response;
      const objPayload = {
        message: "Rider has rejected your offer.",
        trip_id: offers.trip_id,
        trip_status: "reject",
        data: dt,
        to: "driver",
      };
  
      const jsonObject = {
        driver_id: offers.driver_id,
        payload: objPayload,
        token: offers.Driver.d_device_token,
      };
  
      // Ensure socket is ready before emitting
      if (socketRef.current && socketRef.current.connected) {
        socketRef.current.emit("fcm_push_noti", jsonObject);
      } else {
        console.error("WebSocket is not ready to emit notifications.");
      }
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };
  

  const declineNotificationApi = async () => {


        if (reason?.length < 10) {
      toast.dismiss()
      toast.error("Reason should be minimum 10 words. Please fill it out.");
      return
    }

    const params = {
      trip_id: tripCancelData?.trip_id,
      trip_status: "cancel",
      trip_reason: reason,
      driver_id: tripCancelData?.driver_Id,
      is_cancelled: "1",
      is_return_details: "1",
      can_fee_by: "r",
    };
    // const dtype = sessionStorage.getItem("D_device");
    // const dtoken = sessionStorage.getItem("DriverToken");

    if (tripCancelData?.Driver?.d_device_type == "Android") {
      setAndrToken(tripCancelData?.Driver?.d_device_token);
    } else {
      setIosToken(tripCancelData?.Driver?.d_device_token);
    }

    if (!iosToken && !andrToken) {
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/tripapi/updatetrip?api_key=${api_key}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setSocketRunning(false);

      sessionStorage.setItem("tripaccept", JSON.stringify(result.response));

      if (trip_pay_mode === "Card") {
        setCancelStatus(true);
        // setcancelFee(true);
        handleClosepop();
        sessionStorage.removeItem("ride");
        setride(false);
        setcardEndTripPay(false);
        setOffers([]);
        setdecline_trip(false);
        setcancelFee(false);
        setReason("");
        setDriverCancel(true);
        settripcancel(true);
        closePayHandle(false);
        handleClosepop(false);
      }

      localStorage.removeItem("tripid");


      sessionStorage.setItem("payStatus", true);
      setCancelStatus(true);
      // setcancelFee(true);
      handleClosepop();
      localStorage.setItem("tabValue", "bookinghistory" );

  
      sessionStorage.setItem("bookinghistory");
      setride(false);
      // setcardEndTripPay(false);
      setOffers([]);
      // setdecline_trip(false);
      // setcancelFee(false);
      setReason("");
      setDriverCancel(true);
      settripcancel(true);
      // closePayHandle(false);
      // handleClosepop(false);
    
      window.location.reload();

      const Params = {
        trip_id: tripCancelData?.trip_id,
        status: "cancel",
        sound: "driver_cancel.caf",
        android: andrToken,
        ios: iosToken,
        to: "driver",
        message: "Rider has cancelled the Ride.",
      };
      try {
        const response = await fetch(
          `https://apis.getservice.ca/backend/notification/send?api_key=${api_key}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(Params),
          }
        );
        window.location.reload();

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        setdecline_trip(false);
        window.location.reload();
      } catch (error) {
        window.location.reload();

        console.error("Error sending notification:", error);
      }
      setdecline_trip(false);
      // console.log("Notification sent:", result);
    } catch (error) {
      window.location.reload();

      console.error("Error sending notification:", error);
    }
    window.location.reload();

  };


  useEffect(() => {
    if (offerTrip.offeramt != null) setOfferAmt(offerTrip.offeramt);
  }, [offerTrip.offeramt]);

  const handleClose = () => {
    sessionStorage.removeItem("ride");
    setcancelPopup(false);
    setThank(false);
    setride(false);
    localStorage.removeItem("tripid");
    setcardEndTripPay(false);
    setOffers([]);
    setdecline_trip(false);
    setcancelFee(false);
    setReason("");
    setDriverCancel(true);
    closePayHandle(false);
    handleClosepop(false);
    setride(false);
    setCancelStatus(false);
    setShowPopup(false);
    window.location.reload();
  };

  const handleClosepop = () => {
    setcancelPopup(false);
    // setReason("");
  };

  const handleSendOffer = (offer) => {
    setOfferAmt(offer.offer_amt);

    if (offerAmt) {
      if (Number(offerAmt) < Number(Number(tripPayAmountMin).toFixed(2))) {
        toast.error(
          `Please enter an amount greater than or equal to ${tripPayAmountMin}`
        );
        return; // Ensures the function exits early and does not proceed
      } else if ((Number(offerAmt) > Number(Number(tripPayAmountMax).toFixed(2)))){
        toast.error(
          `Please enter an amount greater than or equal to ${tripPayAmountMax}`
        );
        return; // Ensures the function exits early and does not proceed
      }else {
        sendOffer();
        setCustomizeOffer(false);
      }
    }
  };

  const HandleOfferChange = (id) => {
    setCustomizeOffer(true);

    setDriver_Id(id);
  };

  const HandleDriverId = (id) => {
    setDriver_Id(id);
  };

  // console.log(driver_Id);

  const otpValue = sessionStorage.getItem("veri-otp");
  const driverName = sessionStorage.getItem("DriverName");
  const driverContact = sessionStorage.getItem("DriverContact");
  const ridestart = sessionStorage.getItem("ride");

  // console.log(ridestart);
  const getTripHistory = async () => {
    const data = {
      is_request: "0",
      offset: "0",
      limit: "20",
      statuses:
        "request,completed,offer,paid_cancel,begin,assign,arrive,accept,request,paid,cancel,p_cancel_drop,p_cancel_pickup,expired,assigned",
    };

    if (api_key && userid) {
      try {
        const response = await ApiService.post(
          `${GET_TRIPS}?api_key=${api_key}&user_id=${userid}`,
          data
        );
        if (response?.status === 200) {
          setbookingtable(response?.data?.response);
          // if (tid == response?.data.response[0].trip_id) {
          //   // console.log(tid == response.data.response[0].trip_id)
          //   //  setTripDone(false);
          //   // sessionStorage.removeItem("ride");
          //   // setride(false)
          // }
          // console.log(response.data.response)
        }
        if (response?.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
          window.location.reload(); // This will reload the page after navigation
        }
      } catch (error) {

        if ("Request failed with status code 401" == error.message) {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = "/login";
        }
        console.log("Error::", error);
        // localStorage.removeItem("tripid")
        // sessionStorage.clear()
        // navigate("/login");
        // window.location.reload();  // This will reload the page after navigation
      }
    }
  };

  const PaidWitCash = async () => {
    const data = {
      trip_id: trip_Id,
      trip_status: "completed",
      trip_pay_status: "Paid",
      trip_pay_date: formattedTime,
      trip_pay_mode: "Cash",
    };

    try {
      const response = await ApiService.post(
        `/tripapi/updatetrip?api_key=${api_key}`,
        data
      );
      if (response.status === 200) {
        setride(false);

        setThank(true);
        // console.log(response.data.response)
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };

  let csvData = bookingtable?.map((it, ind) => {
    var e = new Date(it.trip_date);
    var utc_offset_gmt = e.getTimezoneOffset();
    e.setMinutes(e.getMinutes() - utc_offset_gmt);
    it.date = moment(e).format("YYYY-MM-DD");
    it.timefor = moment(e).format("hh:mm a");

    // it.date = moment(it.trip_date).format("YYYY-MM-DD");
    // it.timefor = moment(it.trip_date).format("hh:mm A");
    it.status = statusValues[it.trip_status];
    return it;
  });

  const [toastShown, setToastShown] = useState(false);

  useEffect(() => {
    console.log(trip_Id)
    csvData?.forEach((item) => {
      if (
        item.trip_id === trip_Id &&
        (item.trip_status === "paid_cancel" ||
          item.trip_status === "cancel" ||
          item.trip_status === "p_cancel_pickup" ||
          item.trip_status === "p_cancel_drop") &&
        tid &&
        !toastShown // Check if toast has already been shown
      ) {
        setDriverCancel(false);
        if (!tripcancel) {
          sessionStorage.removeItem("ride");
          localStorage.removeItem("tripid");
        }
        if (trip_pay_mode == "Card") {
          sessionStorage.removeItem("ride");
          sessionStorage.removeItem("pm");
          localStorage.removeItem("tripid");
        }

        toast.dismiss()
        toast.error("Your ride has been canceled.");
        window.location.reload()
        setToastShown(true); // Set toastShown to true after showing the toast
      }
    });
  }, [csvData, toastShown, trip_Id, tid, tripcancel]);

  useEffect(() => {
    csvData?.forEach((item) => {
      if (
        item.trip_id === trip_Id &&
        item.trip_status === "completed" &&
        !thank &&
        tid &&
        !toastShown
      ) {
        setDriverCancel(false);
        sessionStorage.removeItem("ride");
        localStorage.removeItem("tripid");
        setThank(true);
        setToastShown(true);
        // Set toastShown to true after showing the toast
      }
    });
  }, [csvData, trip_Id, tid, tripcancel]);

  //Paid With Wallet

  const wamt = sessionStorage.getItem("wmt");

  const getUserData = async () => {
    try {
      const response = await ApiService.post(`${GET_USER}?api_key=${api_key}`, {
        user_id: userid,
      });
      if (response.status === 200) {
        const value = response.data.response;
        sessionStorage.setItem("wmt", value[0]?.u_wallet ? value[0]?.u_wallet : "0");
        return value[0]?.u_wallet ? value[0]?.u_wallet : "0"
      }
    } catch (error) {
      console.log("User fetch ErrorError::", error);
    }
  };

  const payWithWallet = async (tripdata) => {
  const wamtt = await  getUserData()
    sessionStorage.setItem("tripaccept", JSON.stringify(tripdata));

    if (Number(wamtt) < Number(tripdata.trip_pay_amount)) {
      toast.error("Insufficient wallet amount");
    } else {
      try {
        let data = {
          trip_id: tripdata.trip_id,
          driver_id: tripdata.trip_pay_amount.driver_id,
          pay_mode: "wallet",
          pay_amount: tripdata.trip_pay_amount,
          total_amt: tripdata.trip_pay_amount,
          rider_amt: tripdata.trip_pay_amount,
          promo_amt: tripdata.trip_promo_amt,
          commission_amt: tripdata.trip_comp_commision,
          trip_driver_commision: tripdata.trip_driver_commision,
          trans_description: "Trip Payment",
        };

        const result = await ApiService.post(
          `/transactionapi/addtriptrans?api_key=${api_key}&&user_id=${userid}`,
          data
        );

        if (result?.status === 200) {
          const data = {
            trip_id: tripdata.trip_id,
            trip_status: "completed",
            trip_pay_status: "Paid",
            trip_pay_date: formattedTime,
            trip_pay_mode: "wallet",
          };
          try {
            const response = await ApiService.post(
              `/tripapi/updatetrip?api_key=${api_key}`,
              data
            );
            if (response.status === 200) {
              setride(false);
              setCancelStatus(false);
              setThank(true);
              setCancelStatus(false);
              sessionStorage.removeItem("ride");
              setride(false);
              setcardEndTripPay(false);
              setOffers([]);
              setdecline_trip(false);
              setcancelFee(false);
              setReason("");
              setDriverCancel(true);
              settripcancel(true);
              closePayHandle(false);
              handleClosepop(false);
              setride(false);
              setCancelStatus(false);
              setShowPopup(false);
              // console.log(response.data.response)
            }
          } catch (error) {
            // toast.error("Please try again later")
            console.log("Error::", error);
          }
        }
      } catch (error) {
        console.log("Error::", error);
      }
    }
  };

  const declinewithcard = async () => {
    sessionStorage.setItem("tripaccept", JSON.stringify(csvData[0]));

    const data = {
      trip_id: JSON.parse(tripaccept).trip_id,
      trip_pay_status: "Paid",
      trip_pay_date: formattedTime,
      trip_pay_mode: "Card",
    };
    try {
      const response = await ApiService.post(
        `/tripapi/updatetrip?api_key=${api_key}`,
        data
      );
      if (response.status === 200) {
        setride(false);
        setCancelStatus(false);
        sessionStorage.removeItem("ride");
        setride(false);
        setcardEndTripPay(false);
        setOffers([]);
        setdecline_trip(false);
        setcancelFee(false);
        setReason("");
        setDriverCancel(true);
        settripcancel(true);
        closePayHandle(false);
        handleClosepop(false);
        setride(false);
        setCancelStatus(false);
        setShowPopup(false);
        // setThank(true);

        // console.log(response.data.response)
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };

  const payWithWalletCancel = async (tripdata) => {
    const wamtt = await  getUserData()

    if (Number(wamtt) < Number(tripdata.trip_pay_amount)) {
      // console.log(wamt, JSON.parse(tripaccept).trip_pay_amount);
      toast.error("Insufficient wallet amount");
    } else {
      try {
        let data = {
          trip_id: tripdata,
          driver_id: driver_Id,
          pay_mode: "wallet",
          pay_amount: tripdata.trip_pay_amount,
          total_amt: tripdata.trip_pay_amount,
          rider_amt: tripdata.trip_pay_amount,
          promo_amt: tripdata.trip_promo_amt,
          commission_amt: tripdata.trip_comp_commision,
          trip_driver_commision: tripdata.trip_driver_commision,
          trans_description: "Trip Payment",
        };

        const result = await ApiService.post(
          `/transactionapi/addtriptrans?api_key=${api_key}&&user_id=${userid}`,
          data
        );

        if (result?.status === 200) {
          sessionStorage.removeItem("ride");
          setride(false);
          setcardEndTripPay(false);
          setOffers([]);
          setdecline_trip(false);
          setcancelFee(false);
          setReason("");
          setDriverCancel(true);
          settripcancel(true);
          closePayHandle(false);
          handleClosepop(false);
          setCancelStatus(false);
          setShowPopup(false);

          const data = {
            trip_id: tripdata.trip_id,
            trip_pay_status: "Paid",
            trip_pay_date: formattedTime,
            trip_pay_mode: "wallet",
          };
          try {
            const response = await ApiService.post(
              `/tripapi/updatetrip?api_key=${api_key}`,
              data
            );
            if (response.status === 200) {
              setride(false);
              setCancelStatus(false);
              setThank(true);

              // console.log(response.data.response)
            }
          } catch (error) {
            console.log("Error::", error);
          }
        }
      } catch (error) {
        console.log("Error::", error);
      }
    }
  };

  const closePayHandle = () => {
    setCardPay(false);
  }; 
  

  const sendFeedback = async (trip) => {
                             

    const data = {
      trip_id: trip.trip_id,
      trip_feedback: feedback,
      api_key: api_key,
      trip_rating: rating,
    };
    
    let totalRating = 0;
    
    // Check if d_rating and d_rating_count are defined and calculate the new rating
    if (trip.Driver?.d_rating !== undefined && trip.Driver?.d_rating_count !== undefined) {
      totalRating = (
        (parseFloat(trip.Driver.d_rating) * parseFloat(trip.Driver.d_rating_count) + parseFloat(rating)) /
        (parseFloat(trip.Driver.d_rating_count) + 1.0)
      );
    } else {
      // Fallback in case d_rating or d_rating_count is not defined
      totalRating = rating;
    }
    
    const Driverdata = {
      driver_id: trip.Driver?.driver_id,
      api_key: api_key,
      d_rating: totalRating,
      d_rating_count: parseFloat(trip.Driver?.d_rating_count || 0) + 1.0,
    };
    

    
   try {
    const response = await ApiService.post(
      `/driverapi/updatedriverprofile?api_key=${api_key}`,
      Driverdata
    );
    if (response.status === 200) {
      setride(false);
      setfeebackState(false);
      // console.log(response.data.response)
    }
  } catch (error) {
    console.log("Error::", error);
  }
    
    try {
      const response = await ApiService.post(
        `/tripapi/updatetrip?api_key=${api_key}`,
        data
      );
      if (response.status === 200) {
        setride(false);
        setfeebackState(false);
        // console.log(response.data.response)
      }
    } catch (error) {
      console.log("Error::", error);
    }
  };

  const [tripDone, setTripDone] = useState(false);

  // console.log(tid)

  const handleTripComplete = () => {
    if ( csvData?.length> 0 && tid == csvData[0]?.id) {
      if (
        csvData[0]?.trip_status == "completed" ||
        csvData[0]?.trip_status == "cancel"
      ) {
        setTripDone(false);
        sessionStorage.removeItem("ride");
        setride(false);
      }
    }

    csvData?.forEach((item) => {
      if (item) {
        if (item?.trip_id == JSON.parse(tripaccept)?.trip_id) {
          // console.log(item.trip_id == JSON.parse(tripaccept).trip_id, item.trip_id, JSON.parse(tripaccept).trip_id)

          setTripDone(false);
          sessionStorage.removeItem("ride");
          setride(false);
          localStorage.removeItem("tripid");
        }
      }
    });
  };

  const handleClosefees = () => {
    setcancelFee(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleTripComplete();
    }, 2000); // 20 seconds in milliseconds
    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

useEffect(() => {
  const shouldHideOverflow =
    cardPay ||
    cardEndTripPay ||
    offers.length > 1 ||
    (csvData?.length > 1 &&
      csvData[0]?.trip_pay_status !== "Paid" && csvData[0]?.Driver   &&
      (csvData[0]?.trip_status == "cancel" || csvData[0]?.trip_status == "completed")&&
      csvData[0]?.trip_pay_mode !== "Card" &&
      csvData[0]?.trip_pay_amount !== "0" &&  csvData[0]?.is_ride_later == "0" 
      )


  if (shouldHideOverflow) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  // Clean up on unmount
  return () => {
    document.body.style.overflow = "unset";
  };
}, [cardPay, cardEndTripPay, offers, csvData]);


  return (
    <div>
      {  offers?.length > 0 && csvData?.length > 0 && (
        <>
          <div className="popups-container">
            {offers?.map((offer, index) => (
              <>
                <div className="backdrop" />

                <div
                  key={index}
                  className="center-screen pop-up"
                  onClick={() => HandleDriverId(offer?.driver_id)}
                >
                  <div className="offercount">
                    <div className="container-wrapper">
                      <div className="offer">
                        <div className="driver-details">
                          <span>
                            <div>
                              <DriverIcon driver={offer?.Driver} trip={csvData[0]} />
                            </div>
                          </span>
                          {/* <span>
                            <label className="highlight">Locate Driver</label>
                          </span> */}
                        </div>
                        <hr />
                        <div className="offer-detail">
                          <span>
                            <label className="highlight">Driver Offered</label>
                            <div translate="no"  className="amt">
                              {cityInfo?.city_cur}
                              {offer?.offer_amt}
                            </div>
                          </span>
                          <hr className="vertical" />
                          <span>
                            <label className="highlight">You offered</label>
                            <div translate="no"  className="amt">
                              ({cityInfo?.city_cur}
                              {offer?.user_offer_amt})
                            </div>
                            <button
                              style={{ background: "black", color: "white" }}
                              onClick={() => HandleOfferChange(offer.driver_id)}
                            >
                              Customize Offer
                            </button>
                          </span>
                        </div>
                        <hr />
                        <div style={{ display:'flex' }} className="bt-container">
                          <span>
                            <button
                              className="decline-bt"
                              onClick={() => {
                                HandleDriverId(offer?.driver_id);
                                rejectNotificationApi(offer);
                              }}
                            >
                            &times;
                            </button>
                          </span>
                          <span
                            
                            className="accept-container"
                          >
                            <button
                              className="accept-bt"
                              onClick={() => {
                                trip_pay_mode == "Card"
                                  ? tripacceptwithStripe(
                                      offer
                                    )
                                  : tripAccept(
                                      offer 
                                    );
                              }}
                              onChange={() => {
                                HandleDriverId(offer?.driver_id);
                                setDriver_Id(offer?.driver_id);
                              }}
                            >
                              Accept With ({cityInfo?.city_cur}
                              {offer?.offer_amt})
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {customOffer && offer?.driver_id == driver_Id && (
                    <div className="offercount custom-offer">
                      <div className="container-wrapper">
                        <div className="offer">
                          <div className="mb-4">Customize your offer</div>
                          <div className="mb-4">
                            <input
                              type="number"
                              value={offerAmt}
                              onChange={(e) => setOfferAmt(e.target.value)}
                              className="input-field"
                            />
                            <div>Min Value: {tripPayAmountMin}</div>
                          </div>
                          <div>
                            <button
                              style={{ background: "black", color: "white" }}
                              onClick={() => handleSendOffer(offer)}
                              className="send-button"
                            >
                              Send Offer
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  </div>
               </>
            ))}
          </div>
        </>
      )}
   {csvData
  ?.filter(
    (item) =>
      item?.trip_status === "accept" ||
      item?.trip_status === "begin" ||
      item?.trip_status === "arrive"
  )
  .map((filteredItem, index) => (
    <React.Fragment key={index}>
      <div className="backdrop" />
      <div className="center-screen" style={{ position: "fixed" }}>
        <div className="offercount">
          <div style={{ display: "grid" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "4px",
                justifyContent: "center",
              }}
            >
              {filteredItem.trip_status === "accept"
                ? "Driver Assigned"
                : filteredItem.trip_status === "arrive"
                ? "Driver Arrived"
                : "Begin"}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <input
                id="mybtn"
                className="cancelBtn btn-warning btn"
                type="button"
                style={{ outline: "none" }}
                onClick={() =>
                  handleRedirectUrl(
                    `https://apps.getservice.ca/MapRoute/index.php?trip=${filteredItem.trip_id}`
                  )
                }
                value="Tracking"
              />
            </div>
            <hr style={{ width: "100%" }} />
            <div>
              {expiryTimestamp && filteredItem?.is_ride_later == "0" && filteredItem?.trip_status != "begin" ? (
                <MyTimer expiryTimestamp={expiryTimestamp} />
              ) : (
                ""
              )}
            </div>
            {
              filteredItem?.trip_status != "begin" && (
                <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <div>OTP:</div>
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    marginLeft: "5px",
                  }}
                >
                  {filteredItem?.otp}
                </div>
              </div>
              )
            }
        
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                Driver Name:{" "}
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                  translate="no"
                >
                  {filteredItem?.Driver?.d_name}
                </div>
              </div>
              <hr className="vertical2" />
              <div
                style={{
                  display: "grid",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                Driver Contact:{" "}
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                  translate="no"
                >
                  {driverContact}
                </div>
              </div>
            </div>

             {filteredItem?.trip_status != "begin" && (
                       <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "25px",
              }}
            >
              <button
                style={{
                  background: "black",
                  color: "white",
                  padding: "5px 10px 5px 10px",
                }}
                onClick={()=>handleCancelClick(filteredItem)}
              >
                Cancel Ride
              </button>
            </div>
                    )}
         
          </div>
        </div>
      </div>
    </React.Fragment>
  ))}


      {thank && (
        <>
          {" "}
          <div className="backdrop" />
          <div className="center-screen " style={{ position: "fixed" }}>
            <div className="offercount">
              <div
                style={{
                  marginBottom: "20px",
                  padding: "25px",
                }}
              >
                Thank You For using GetRide!
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  style={{
                    background: "black",
                    color: "white",
                  }}
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {cardPay && (
        <div className="popup-overlay" style={{ paddingBottom: "50px" }}>
          <div className="popup-content">
            <div
              style={{
                float: "right",
              }}
            >
              <button
                style={{
                  background: "black",
                  color: "white",
                  padding: "5px 20px",
                }}
                onClick={closePayHandle}
              >
                X
              </button>
            </div>
            <h2 className="popup-title">Payment</h2>
            <PaymentComponent
              setride={setride}
              setThank={setThank}
              date={csvData[0]?.trip_date}
              payAmount={payAmount}
              setDriverCancel={setDriverCancel}
              closePayHandle={closePayHandle}
              offers={offers}
              startTimer={startTimer}
              city_cur={cityInfo.pg_cur}
            />
          </div>
        </div>
      )}

      {cancelPopup && (
        <>
          <div className="backdrop" />

          <div className="center-screen " style={{ position: "fixed" }}>
            <div
              className="popup"
              style={{ boxShadow: "0px 0px 0px 1px black" }}
            >
              <div className="container-wrapper">
                <div className="popup-inner">
                  <div className="tripcancle" style={{ marginTop: "20px" }}>
                    Reason:{" "}
                    <input
                      className="Noteinput"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      style={{ marginLeft: "5px", width: "60%" }}
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      style={{
                        background: "black",
                        color: "white",
                        padding: "5px 10px 5px 10px",
                        marginRight: "10px",
                      }}
                      onClick={declineNotificationApi}
                    >
                      Submit
                    </button>
                    <button
                      style={{
                        background: "grey",
                        color: "white",
                        padding: "5px 10px 5px 10px",
                      }}
                      onClick={handleClosepop}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {cardEndTripPay && (
        <div
          className="popup-overlay"
          style={{ zIndex: 99999, padding: "50px" }}
        >
          <div className="popup-content">
            <div
              style={{
                float: "right",
              }}
            >
              <button
                style={{
                  background: "black",
                  color: "white",
                  padding: "5px 20px",
                }}
                onClick={handlePayCardOptionClose}
              >
                X
              </button>
            </div>
            <h2 className="popup-title">Payment</h2>
            <PaymentCardComponent
              trip_Id={trip_Id}
              setride={setride}
              setThank={setThank}
              date={csvData[0]?.trip_date}
              amount={payAmount}
              setDriverCancel={setDriverCancel}
              closePayHandle={handlePayCardOptionClose}
              offers={offers}
              cancelStatus={cancelStatus}
              declineNotificationApi={declinewithcard}
              setCancelStatus={setCancelStatus}
              city_cur={cityInfo.pg_cur}
            />
          </div>
        </div>
      )}

      {/*    {cancelFee && trip_pay_mode !== "Card" && (
        <div className="popup-container">
          <div
            className="popup4"
            style={{ boxShadow: "0px 0px 0px 1px black" }}
          >
            <select
              style={{
                width: "190px",
                padding: "3px",
                border: "1px solid #eee",
              }}
              onChange={(e) => {
                const selectedValue = e.target.value;
                if (selectedValue === "cash") {
                  PaidWitCash();
                } else if (selectedValue === "card") {
                  handlePayCardOption();
                  handleClosefees();
                } else if (selectedValue === "wallet") {
                  payWithWallet();
                  handleClosefees();
                }
              }}
            >
              <option selected disabled>
                Select Payment Method
              </option>
              {city_id == cityInfo.city_id && (
                <option value="wallet">Wallet</option>
              )}
              <option value="card">Card</option>
            </select>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  background: "black",
                  color: "white",
                  padding: "5px 10px 5px 10px",
                }}
                onClick={handleClosefees}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
 */}

  {/*     {csvData && 
        csvData?.length > 0 && csvData[0]?.Driver !== null &&
        csvData[0]?.trip_pay_status !== "Paid" &&
        csvData[0]?.trip_pay_mode !== "Card" &&
        csvData[0]?.trip_pay_amount !== "0" &&
        csvData[0]?.is_ride_later == "0" && (
          <>
            {csvData[0]?.trip_status === "cancel" ||
            csvData[0]?.trip_status == "completed" ? (
              <React.Fragment key={csvData[0].trip_id}>
                <div className="popups-container">
                  <div className="backdrop" />
                  <div
                    className="center-screen"
                    style={{ position: "absolute", minHeight: "365px" }}
                  >
                    <div className="offercount">
                      <div style={{ display: "grid" }}>
                        <div style={{ display: "grid", position: "relative" }}>
                          <div className="dot red-dot" />
                          <div style={{ marginLeft: "20px" }}>
                            Pickup Location:
                            <div className="loc" style={{ display: "grid" }}>
                              {csvData[0].trip_from_loc}
                            </div>
                          </div>
                          <div className="vertical-line"></div>
                          <div className="dot green-dot" />
                          <div style={{ marginLeft: "20px" }}>
                            Drop Location:
                            <div className="loc">{csvData[0].trip_to_loc}</div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "20px",
                            marginBottom: "20px",
                          }}
                        >
                          Status:
                          <span
                            style={{ fontWeight: "700", marginLeft: "5px" }}
                          >
                            {csvData[0].trip_pay_status
                              ? csvData[0].trip_pay_status
                              : "Unpaid"}
                          </span>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          Pay Amount:
                          <span
                            style={{ fontWeight: "700", marginLeft: "5px" }}
                          >
                            {cityInfo?.city_cur} {csvData[0]?.trip_pay_amount}
                          </span>
                        </div>

                        {feebackState ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <ReactStars
                                count={5}
                                edit={true}
                                value={rating}
                                isHalf={true}
                                a11y={true}
                                activeColor="#ffd700"
                                onChange={ratingChanged}
                                size={25}
                                color2={"#ffd700"}
                              />
                            </div>
                            <div
                              className="feedback"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              Feedback:
                              <input
                                type="text"
                                onChange={handleInputChange}
                                className="Noteinput"
                                style={{ marginLeft: "5px" }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <button
                                style={{
                                  background: "black",
                                  color: "white",
                                  padding: "5px 20px",
                                }}
                                onClick={() =>
                                  sendFeedback(
                                    csvData[0].Driver.api_key,
                                    csvData[0].trip_id
                                  )
                                }
                              >
                                Submit Feedback
                              </button>
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            Thank You For Your Valuable Feedback
                          </div>
                        )}

                        {csvData &&
                        csvData[0]?.trip_pay_status?.toLowerCase() !== "paid" &&
                        csvData[0]?.trip_pay_amount !== "0" ? (
                          <>
                            <div className="popup-container">
                              {showPopup && trip_pay_mode !== "Card" && (
                                <div
                                  className="popup"
                                  style={{
                                    boxShadow: "0px 0px 0px 1px black",
                                    display: "grid",
                                  }}
                                >
                                  <select
                                    style={{
                                      width: "190px",
                                      padding: "3px",
                                      border: "1px solid black",
                                    }}
                                    onChange={(e) => {
                                      const selectedValue = e.target.value;
                                      if (selectedValue === "cash") {
                                        PaidWitCash();
                                      } else if (selectedValue === "card") {
                                        handlePayCardOption(csvData[0].trip_id);
                                      } else if (selectedValue === "wallet") {
                                        csvData[0]?.trip_status === "completed"
                                          ? payWithWallet(csvData[0])
                                          : payWithWalletCancel(
                                              csvData[0]
                                            );
                                      }
                                    }}
                                  >
                                    <option selected disabled>
                                      Select Payment Method
                                    </option>
                                    {city_id === cityInfo?.city_id && (
                                      <option value="wallet">Wallet</option>
                                    )}
                                    <option value="card">Card</option>
                                  </select>
                                  <button
                                    onClick={handlePayClick}
                                    style={{
                                      background: "black",
                                      color: "white",
                                      padding: "5px 20px",
                                    }}
                                  >
                                    Close
                                  </button>
                                </div>
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                onClick={
                                  trip_pay_mode !== "Card"
                                    ? handlePayClick
                                    : handleCloseTripDetail
                                }
                                style={{
                                  background: "black",
                                  color: "white",
                                  padding: "5px 20px",
                                }}
                              >
                                {trip_pay_mode !== "Card" ? "Pay" : "Close"}
                              </button>
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={handleCloseTripDetail}
                              style={{
                                background: "black",
                                color: "white",
                                padding: "5px 20px",
                              }}
                            >
                              Close
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </>
        )} */}

{csvData &&
  csvData.length > 0 &&
  csvData
    ?.filter(
      (trip) =>
        trip.Driver !== null &&
        trip.trip_pay_status !== "Paid" &&
        trip.trip_pay_mode !== "Card" &&
        trip.is_ride_later == "0" &&
        trip.trip_pay_amount !== "0" &&
        (trip.trip_status === "cancel" || trip.trip_status === "completed")
    )
    .map((trip, index) => (
       <React.Fragment key={trip.trip_id}>
                <div className="popups-container">
                  <div className="backdrop" />
                  <div
                    className="center-screen"
                    style={{ position: "absolute", minHeight: "365px" }}
                  >
                    <div className="offercount">
                      <div style={{ display: "grid" }}>
                        <div style={{ display: "grid", position: "relative" }}>
                          <div className="dot red-dot" />
                          <div style={{ marginLeft: "20px" }}>
                            Pickup Location:
                            <div className="loc" style={{ display: "grid" }}>
                              {trip.trip_from_loc}
                            </div>
                          </div>
                          <div className="vertical-line"></div>
                          <div className="dot green-dot" />
                          <div style={{ marginLeft: "20px" }}>
                            Drop Location:
                            <div className="loc">{trip.trip_to_loc}</div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "20px",
                            marginBottom: "20px",
                          }}
                        >
                          Status:
                          <span
                            style={{ fontWeight: "700", marginLeft: "5px" }}
                          >
                            {trip.trip_pay_status
                              ? trip.trip_pay_status
                              : "Unpaid"}
                          </span>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          Pay Amount:
                  <span
                  translate="no" 
                            style={{ fontWeight: "700", marginLeft: "5px" }}
                          >
                            {cityInfo?.city_cur} {trip?.trip_pay_amount}
                          </span>
                        </div>

                        {feebackState ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                    >
                      <span            translate="no" 
                      >
                    
                        
                        <ReactStars
                        count={5}
                        edit={true}
                        value={rating}
                        isHalf={true}
                        a11y={true}
                        activeColor="#ffd700"
                        onChange={ratingChanged}
                        size={25}
                color2={"#ffd700"}
     
                      />
                      </span>
                    
                            </div>
                            <div
                              className="feedback"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              Feedback:
                              <input
                                type="text"
                                onChange={handleInputChange}
                                className="Noteinput"
                                style={{ marginLeft: "5px" }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <button
                                style={{
                                  background: "black",
                                  color: "white",
                                  padding: "5px 20px",
                                }}
                                onClick={() =>
                                  sendFeedback(
                                    trip
                                  )
                                }
                              >
                                Submit Feedback
                              </button>
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            Thank You For Your Valuable Feedback
                          </div>
                        )}

                        {trip &&
                        trip?.trip_pay_status?.toLowerCase() !== "paid" &&
                        trip?.trip_pay_amount !== "0" ? (
                          <>
                            <div className="popup-container">
                              {showPopup && trip_pay_mode !== "Card" && (
                                <div
                                  className="popup"
                                  style={{
                                    boxShadow: "0px 0px 0px 1px black",
                                    display: "grid",
                                  }}
                                >
                                  <select
                                    style={{
                                      width: "190px",
                                      padding: "3px",
                                      border: "1px solid black",
                                    }}
                                    onChange={(e) => {
                                      const selectedValue = e.target.value;
                                      if (selectedValue === "cash") {
                                        PaidWitCash();
                                      } else if (selectedValue === "Card") {
                                        handlePayCardOption(trip.trip_id);
                                      } else if (selectedValue === "Wallet") {
                                        trip?.trip_status === "completed"
                                          ? payWithWallet(trip)
                                          : payWithWalletCancel(
                                              trip
                                            );
                                      }
                                    }}
                                  >
                                    <option selected disabled>
                                      Select Payment Method
                                    </option>
  {city_pay_options
  ?.filter((data) => data.name !== "Cash")
  .map((data) => (
    <option value={data.name} key={data.id}>
      {data.name}
    </option>
  ))}

                                  </select>
                                  <button
              onClick={
                            () => { handlePayClick(trip) }
                                 }                                    style={{
                                      background: "black",
                                      color: "white",
                                      padding: "5px 20px",
                                    }}
                                  >
                                    Close
                                  </button>
                                </div>
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                onClick={
                            () => { handlePayClick(trip) }
                                 }
                                style={{
                                  background: "black",
                                  color: "white",
                                  padding: "5px 20px",
                                }}
                              >
                                {trip_pay_mode !== "Card" ? "Pay" : "Close"}
                              </button>
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={handleCloseTripDetail}
                              style={{
                                background: "black",
                                color: "white",
                                padding: "5px 20px",
                              }}
                            >
                              Close
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
    ))}


      {tripcancel && (
        <>
          {" "}
          {csvData &&
            csvData?.map((item) =>
              (item.trip_status === "cancel" ||
                item.trip_status === "paid_cancel" ||
                item.trip_status === "complete") &&
              (item.trip_pay_mode == "Card" || item.trip_pay_amount == "0") &&
              tid == item.trip_id ? (
                <React.Fragment key={item.trip_id}>
                  <div className="backdrop" />

                  <div
                    className="center-screen"
                    style={{ position: "absolute" }}
                  >
                    <div className="offercount">
                      <div style={{ display: "grid" }}>
                        <div style={{ display: "grid", position: "relative" }}>
                          <div className="dot red-dot" />
                          <div style={{ marginLeft: "20px" }}>
                            Pickup Location:
                            <div className="loc" style={{ display: "grid" }}>
                              {item.trip_from_loc}
                            </div>
                          </div>
                          <div className="vertical-line"></div>
                          <div className="dot green-dot" />
                          <div style={{ marginLeft: "20px" }}>
                            Drop Location:
                            <div className="loc">{item.trip_to_loc}</div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "20px",
                            marginBottom: "20px",
                          }}
                        >
                          Status:
                          <span
                            style={{ fontWeight: "700", marginLeft: "5px" }}
                          >
                            {item.trip_pay_status
                              ? item.trip_pay_status
                              : "Unpaid"}
                          </span>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          Pay Amount:
                            <span
                            translate="no" 
                            style={{ fontWeight: "700", marginLeft: "5px" }}
                          >
                            {cityInfo?.city_cur} {item.trip_pay_amount}
                          </span>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            onClick={handleCloseTripDetail}
                            style={{
                              background: "black",
                              color: "white",
                              padding: "5px 20px",
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )
            )}
        </>
      )}

      {csvData &&
        csvData.map((item) => {
          const isCompleted = item.trip_status === "completed";
          const isPayStatusMatch = item.trip_pay_status === "Paid"; // Replace 'expected_status' with the correct value.
          const isTripIdMatch = Number(storage_trip_id) === Number(item.trip_id);
      
          if (isCompleted && isPayStatusMatch && isTripIdMatch) {
            return (
              <React.Fragment key={item.trip_id}>
                <div className="backdrop" />
      
                <div className="center-screen" style={{ position: "fixed" }}>
                  <div className="offercount">
                    <div style={{ display: "grid" }}>
                      <div style={{ display: "grid", position: "relative" }}>
                        <div className="dot red-dot" />
                        <div style={{ marginLeft: "20px" }}>
                          Pickup Location:
                          <div className="loc" style={{ display: "grid" }}>
                            {item.trip_from_loc}
                          </div>
                        </div>
                        <div className="vertical-line"></div>
                        <div className="dot green-dot" />
                        <div style={{ marginLeft: "20px" }}>
                          Drop Location:
                          <div className="loc">{item.trip_to_loc}</div>
                        </div>
                      </div>
      
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        Status:
                        <span style={{ fontWeight: "700", marginLeft: "5px" }}>
                          {item.trip_pay_status || "Unpaid"}
                        </span>
                      </div>
      
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        Pay Amount:
                        <span translate="no"  style={{ fontWeight: "700", marginLeft: "5px" }}>
                          {cityInfo?.city_cur} {item.trip_pay_amount}
                        </span>
                      </div>
                      {feebackState ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <span
                              translate="no" 
                          >
                        
                            
                            <ReactStars
                            count={5}
                            edit={true}
                            value={rating}
                            isHalf={true}
                            a11y={true}
                            activeColor="#ffd700"
                            onChange={ratingChanged}
                            size={25}
                    color2={"#ffd700"}/>
         
                          </span>
                          </div>
                          <div
                            className="feedback"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            Feedback:
                            <input
                              type="text"
                              onChange={handleInputChange}
                              className="Noteinput"
                              style={{ marginLeft: "5px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <button
                              style={{
                                background: "black",
                                color: "white",
                                padding: "5px 20px",
                              }}
                              onClick={() =>
                                sendFeedback(
                                  item
                                )
                              }
                            >
                              Submit Feedback
                            </button>
                          </div>
                        </>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          Thank You For Your Valuable Feedback
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          onClick={handleCloseTripDetail}
                          style={{
                            background: "black",
                            color: "white",
                            padding: "5px 20px",
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          }
          return null;
        })}
      
    </div>
  );
};

export default BidFrom;
