import React, { useMemo, useState } from "react";
import "./drivericon.css";
import ReactStars from "react-stars";

const DriverIcon = ({ driver, trip }) => {

  const [dis, setDis] = useState()
  // Function to determine the star color based on rating
  const getStarBackgroundColor = (rating) => {
    if (rating >= 4) return "#4CAF50"; // Green for excellent ratings
    if (rating >= 3) return "#FFC107"; // Yellow for average ratings
    return "#FF5722"; // Red for low ratings
  };

  const rating = driver?.d_rating ?? 0; // Default to 0 if no rating
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6379; // Earth's radius in kilometers
    const toRadians = (deg) => (deg * Math.PI) / 180;

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in kilometers
  };

  const calculateDuration = (distance, averageSpeed = 40) => {
    return distance / averageSpeed; // Duration in hours
  };

  const calculateEstimatedArrivalTime = (orLat, orLng, deLat, deLng) => {
    const distance = calculateDistance(orLat, orLng, deLat, deLng); // In kilometers
    const duration = calculateDuration(distance); // Duration in hours
    setDis(distance);
    const durationInMinutes = Math.round(duration * 60); // Convert to minutes
    return `${durationInMinutes == 0 ? 1 : durationInMinutes} min`;
  };

  const orLat = driver?.d_lat;
  const orLng = driver?.d_lng;
  const deLat = trip?.trip_scheduled_pick_lat;
  const deLng = trip?.trip_scheduled_pick_lng;


  const estimatedArrivalTime = useMemo(() => {
    return calculateEstimatedArrivalTime(orLat, orLng, deLat, deLng)
  }, [orLat, orLng, deLat, deLng]);

  console.log(driver)

  return (
    <div className="driver_icon_main">
      <div className="driver_icon_main_image">
        <img
          src={`https://apps.getservice.ca/webservices/images/originals/${driver?.d_profile_image_path}`}
          alt=""
        />
      </div>
      <div className="driver_icon_main_detail">
        <div className="driver_icon_main_detail_first">
          <div translate="no" className="driver_icon_main_detail_first_left">
            {driver?.d_name && driver.d_name.length > 12
              ? `${driver.d_name.substring(0, 12)}...`
              : driver?.d_name}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            > <div className="driver_icon_main_detail_second" style={{display:"flex"}}>
            {driver?.num_trip} Trip
          </div>
              <div
                style={{
                  width: "40px",
                  height: "25px",
                  display: "flex",
                  fontSize:"12px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#fff", // Star icon color
                  borderRadius: "10%", // Make it circular
                  backgroundColor: getStarBackgroundColor(rating), // Dynamic background color
                }}
              >
                &nbsp;{Number(rating)?.toFixed(1)}
                &nbsp;
                <span
                  style={{
                    color: "#fff", // Star icon color
                    fontSize: "18px", // Adjust star size
                  }}
                >
                  ★
                </span>
              </div>
            </div>
          </div>

        </div>
       
        <div translate="no" className="driver_icon_main_detail_second">
          {driver?.car_name} {driver?.car_model} {driver?.car_make}
        </div>
        {!isNaN(dis) && (<div className="driver_icon_main_detail_third">{dis?.toFixed(2) == 0.00 ? 0.20 : dis?.toFixed(2)}km ({estimatedArrivalTime == 0 ? 1 : estimatedArrivalTime})</div>)}
      </div>
    </div>
  );
};

export default DriverIcon;
