// import { useEffect, useState } from "react";

import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./stripe.css";
// Your Stripe publishable key
// const stripePromise = loadStripe('pk_test_51NcUyLSC5UPsHJr4yzy9IgRZe9zmAOymjpI4D2yj616PaHMkULAToS1XC4wsb1UgVCbzuf1S4Bz8HxjCsyqLP1hB003qAdaSVM');

const AddCardForm = (props) => {
  const stripe = useStripe(); // Stripe instance
  const elements = useElements(); // Elements instance
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(""); // Error state
  const [success, setSuccess] = useState(""); // Success message state
  const [savedCards, setSavedCards] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    // Get card details from CardElement
    const cardElement = elements.getElement(CardElement);

    // Create a token with Stripe using the card element
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card", // Specify that this is a card payment method
      card: cardElement,
    });

    if (error) {
      setError(error.message);
    } else {
      // Token is created, send it to the server for further processing
      console.log("Success! Token:", paymentMethod);

      const response = await fetch(
        "https://apps.getservice.ca/webservices/1.1.3/index.php/constantapi/attachpaymentmethod",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customer_id: sessionStorage.getItem("stripe_cust_id"),
            payment_method_id: paymentMethod.id,
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        setSuccess("Card saved successfully!");
        getCard();

        // onCardAdded(); // Call the function to refresh saved cards
      } else {
        setError("Failed to save card: " + data.error);
      }
      setSuccess("Card added successfully!");
      getCard();

      // Call a function to send the token to your backend
    }

    setLoading(false);
  };

  const getCard = () => {
    const url =
      "https://apps.getservice.ca/webservices/1.1.3/index.php/constantapi/getpaymentmethods";

    const data = {
      customer_id: sessionStorage.getItem("stripe_cust_id"), // Replace 'your_customer_id' with the actual customer ID value
    };

    fetch(url, {
      method: "POST", // HTTP method
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Convert data object to a JSON string
    })
      .then((response) => response.json()) // Parse the JSON response
      .then((result) => {
        setSavedCards(result.response.data);
        console.log("Success:", result); // Handle the response data here
      })
      .catch((error) => {
        console.error("Error:", error); // Handle errors here
      });
  };

  useEffect(() => {
    getCard();
  }, []);

  const handleDeleteCard = (payment_method_id) => {
    if (!payment_method_id) {
      return;
    }

    const url =
      "https://apps.getservice.ca/webservices/1.1.3/index.php/constantapi/detachpaymentmethod";

    const data = {
      payment_method_id: payment_method_id, // Replace 'your_customer_id' with the actual customer ID value
    };

    fetch(url, {
      method: "POST", // HTTP method
      headers: {
        "Content-Type": "application/json", // Specify that you're sending JSON data
      },
      body: JSON.stringify(data), // Convert data object to a JSON string
    })
      .then((response) => response.json()) // Parse the JSON response
      .then((result) => {
        console.log("Success:", result); // Handle the response data here
        getCard();
      })
      .catch((error) => {
        console.error("Error:", error); // Handle errors here
      });
  };

  const handlePay = (id) => {
    localStorage.setItem("pm", id);
    props.handleCloseCard();
  };

  return (
    <>
      <div style={{ marginBottom: 50 }}>
        {savedCards?.map((card, index) => (
          <div className="wrapper" key={index}>
            <div className="cards">
              <div className="face">
                <div className="main">
                  <div className="number">XXXX-XXXX-XXXX-{card.card.last4}</div>
                </div>
                <div
                  className="dates"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      maxWidth: "100px",
                    }}
                  >
                    <div className="start">
                      {card.card.exp_month}/{card.card.exp_year}
                    </div>
                    <div className="end" style={{ paddingLeft: 20 }}>
                      {card.card.brand}
                    </div>
                  </div>

                  <div
                    className="end"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <button
                      style={{
                        background: "#d3ae2a",
                        padding: "5px 10px",
                        border: "none",
                        fontSize: "20px",
                        fontWeight:600
                      }}
                      onClick={() => handlePay(card.id)}
                    >
                      Pay
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      style={{
                        background: "transparent",
                        color: "red",
                        border: "none",
                      }}
                      onClick={() => handleDeleteCard(card.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M 10 2 L 9 3 L 5 3 C 4.4 3 4 3.4 4 4 C 4 4.6 4.4 5 5 5 L 7 5 L 17 5 L 19 5 C 19.6 5 20 4.6 20 4 C 20 3.4 19.6 3 19 3 L 15 3 L 14 2 L 10 2 z M 5 7 L 5 20 C 5 21.1 5.9 22 7 22 L 17 22 C 18.1 22 19 21.1 19 20 L 19 7 L 5 7 z M 9 9 C 9.6 9 10 9.4 10 10 L 10 19 C 10 19.6 9.6 20 9 20 C 8.4 20 8 19.6 8 19 L 8 10 C 8 9.4 8.4 9 9 9 z M 15 9 C 15.6 9 16 9.4 16 10 L 16 19 C 16 19.6 15.6 20 15 20 C 14.4 20 14 19.6 14 19 L 14 10 C 14 9.4 14.4 9 15 9 z"
                          fill="red"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <form onSubmit={handleSubmit}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "20px",
                fontWeight: 600,
                color: "#424770",
                "::placeholder": { color: "#aab7c4" },
              },
              invalid: { color: "#9e2146" },
            },
          }}
        />
        <button
          type="submit"
          style={{
            background: "#d3ae2a",
            border: "none",
            padding: "5px 10px",
            marginTop: "20px",
            color: "white",
          }}
          disabled={!stripe || loading}
        >
          {loading ? "Processing..." : "Add Card"}
        </button>

        {success && <div style={{ color: "green" }}>{success}</div>}
      </form>
    </>
  );
};

const PaymentComponent = (props) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/constantapi/getstripekey`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (r) => {
      const response = await r.json();
      const stripeKey = response.response.stripe_p_key;
      console.log(stripeKey)
      const stripe = await loadStripe(stripeKey); // Wait for the promise to resolve
      setStripePromise(stripe); // Set the resolved object in state
    });
  }, []);

  console.log("hii",stripePromise); // Will log 'null' initially, and then the resolved stripe object

  // Only render <Elements /> if stripePromise is not null
  return (
    <>
      {stripePromise ? (
        <Elements stripe={stripePromise}>
          <AddCardForm handleCloseCard={props.handleCloseCard} />
        </Elements>
      ) : (
        <div>Loading...</div> // Optional loading state
      )}
    </>
  );
};

export default PaymentComponent;
