const getEstimatedFare = function (cat_id, Est_Fare_List) {
    if (Est_Fare_List) {
        const fare = Est_Fare_List[cat_id]?.trip_pay_amount
        return fare ;
    }

};

/**
 * Redirects to a specified URL with a given target.
 * 
 * @param {string} url - The URL to navigate to.
 * @param {string} [target="_blank"] - The target where the URL will open. Possible values:
 *   - "_blank" (default): Opens in a new tab or window.
 *   - "_self": Opens in the same window or tab.
 *   - "_parent": Opens in the parent frame.
 *   - "_top": Opens in the full body of the window.
 */

const handleRedirectUrl = (url, target="_blank") => {
    const a = document.createElement('a')
    a.href=url
    a.target=target
    a.click()
}


module.exports = {
    getEstimatedFare,
    handleRedirectUrl
};