import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./cardForm";
import { loadStripe } from "@stripe/stripe-js";

function PaymentCardComponent(props) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const tripaccept = sessionStorage.getItem("tripaccept");

    console.log(tripaccept)
   useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/constantapi/getstripekey`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(async (r) => {
      const response = await r.json();
      // console.log( response.response.stripe_p_dev_key )
      setStripePromise(loadStripe(response.response.stripe_p_key));
    });
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/constantapi/walletcreatepaymentintent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: JSON.parse(tripaccept).trip_pay_amount,
          currency: props.city_cur,
          description: "getride booking website",
        }),
      }
    ).then(async (result) => {
      var response = await result.json();

      // console.log(response);
      setClientSecret(response.response);
    });
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/*         <img src="images/logo.png" height={"100px"} />
         */}{" "}
        <h1 style={{ color: "blueviolet", fontWeight: "700" }} translate="no">Stripe</h1>
      </div>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm
            setride={props.setride}
            clientSecret={clientSecret}
            setThank={props.setThank}
            date={props.date}
            offers={props.offers}
            amount={props.amount}
            setDriverCancel={props.setDriverCancel}
                      closePayHandle={props.closePayHandle}
                      cancelStatus={props.cancelStatus}
                      declineNotificationApi={props.declineNotificationApi}
                      setCancelStatus={props.setCancelStatus}
                      trip_Id={props.trip_Id}

          />
        </Elements>
      )}
    </div>
  );
}

export default PaymentCardComponent;
