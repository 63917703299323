import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./common/Header";
import Footer from "./common/Footer";
import MainRoutes from "./routes/MainRoutes";
import BidFrom from "./components/biding";
import { useLocation } from "react-router-dom";
import ApiService from "./utils/Api";
import { GET_CONSTANTS } from "./utils/Endpoint";
import NormalTag from "./components/meta/NormalTag";
import ScrollToTop from "./common/ScrollToTop";
import { toast } from "react-toastify";
import './i181n'; // Import i18n configuration

function App() {
  const location = useLocation();

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
        includedLanguages: "en,fr,es", // Restrict to English, French, and Spanish
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    // Check if the pathname is not "/schedule-form" or "/booking-complete"
       const addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);

      // Assign the callback to the global window object
      window.googleTranslateElementInit = googleTranslateElementInit;

      // Cleanup: remove the script when the component unmounts or dependencies change
      return () => {
        document.body.removeChild(addScript);
      };
   }, []); // Add location.pathname as a dependency

  
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("auth") ? true : false
  );

  useEffect(() => {
    setIsAuthenticated(sessionStorage.getItem("auth") ? true : false);
  }, []);

  useEffect(() => {
    getConstantData();
  }, []);

  const getConstantData = async (data) => {
    try {
      const response = await ApiService.post(`${GET_CONSTANTS}`, data);
      if (response.status === 200) {
        const value = response.data.response;
        if (Array.isArray(value) && value.length > 0) {
          localStorage.setItem("driver_radius", value[43]?.cvalue);
          localStorage.setItem("rl_driver_radius", value[0]?.cvalue);
        }
      }
    } catch (error) {
      console.log("User fetch ErrorError::", error);
    }
  };
  toast.dismiss()

  // useEffect(() => {
  //   // Load the Google Translate script dynamically
  //   if (["/schedule-form", "/booking-complete"].includes(location.pathname)) {
  //     const script = document.createElement("script");
  //     script.type = "text/javascript";
  //     script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  //     document.body.appendChild(script);
  
  //     // Define the googleTranslateElementInit function globally
  //     window.googleTranslateElementInit = () => {
  //       const language = sessionStorage.getItem("language");
  //       const translatedLang = language === "en" ? "" : `/no/${language}`;
  
  //       // Set the googtrans cookie
  //       setCookie("googtrans", translatedLang, 1);
  
  //       // Initialize the Google Translate element
  //       new window.google.translate.TranslateElement(
  //         {
  //           pageLanguage: language === "en" ? " " : "no",
  //           layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
  //         },
  //         "google_translate_element"
  //       );
  //     };
  
  //     // Helper function to set a cookie
  //     const setCookie = (key, value, expiry) => {
  //       const expires = new Date();
  //       expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);
  //       document.cookie = `${key}=${value};expires=${expires.toUTCString()}`;
  //     };
  
  //     // Cleanup the script when the component unmounts
  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }
  // }, []); // Add location.pathname as a dependency if necessary
  


  return (
    <>
       {!["/schedule-form", "/booking-complete"].includes(location.pathname) && (
        <Header
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
        />
      )}
      {/* Pass setIsAuthenticated to the Header component */}
      {!["/schedule-form", "/booking-complete"].includes(location.pathname) && (
        <BidFrom />
      )}
      <div className="App">
        <ScrollToTop />
        <MainRoutes setIsAuthenticated={setIsAuthenticated} />{" "}
        {/* Pass setIsAuthenticated to the MainRoutes component */}
        <ToastContainer />
      </div>
      {!["/schedule-form", "/booking-complete"].includes(location.pathname) && (
        <Footer />
      )}
      {/* Include the Footer component */}
    </>
  );
}

export default App;
